import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";
import ActivityType from "./../activityTypes/ActivityType";
import BrandsFilter from "./BrandsFilter";
import brandImg from "@/assets/images/brand.svg";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import i18n from "@/i18n";
import store from "@/store";
import { viewFileFromServer } from "@/utils/functions";

export default class Brand extends BaseEntityModel {
  defaultImg = brandImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.brandToken = "";
    this.brandCode = "";
    this.brandNameCurrent = "";
    this.brandNameEn = "";
    this.brandNameAr = "";
    this.brandNameUnd = "";
    this.brandDiscountPercentage = "";
    this.mapIconLink = "";
    this.mapLink = "";
    this.mapLinkStatus = "";
    this.faceBookIconLink = "";
    this.faceBookLink = "";
    this.faceBookLinkStatus = "";
    this.twitterIconLink = "";
    this.twitterLink = "";
    this.twitterLinkStatus = "";
    this.instagramIconLink = "";
    this.instagramLink = "";
    this.instagramLinkStatus = "";
    this.webSiteIconLink = "";
    this.webSiteLink = "";
    this.webSiteLinkStatus = "";
    this.brandDescriptionCurrent = "";
    this.brandDescriptionAr = "";
    this.brandDescriptionEn = "";
    this.brandDescriptionUnd = "";
    this.brandImagePath = "";
    this.brandNotes = "";
    this.brandArchiveStatus = "";
    this.activityTypeToken = "";
    this.activityTypeData = new ActivityType();
    this.dailyCode = "";
    this.fullCode = "";
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.brandToken = data.brandToken;
      this.brandCode = data.brandCode;
      this.brandNameCurrent = data.brandNameCurrent;
      this.brandNameEn = data.brandNameEn;
      this.brandNameAr = data.brandNameAr;
      this.brandNameUnd = data.brandNameUnd;
      this.brandDiscountPercentage = data.brandDiscountPercentage;
      this.mapIconLink = data.mapIconLink;
      this.mapLink = data.mapLink;
      this.mapLinkStatus = data.mapLinkStatus;
      this.faceBookIconLink = data.faceBookIconLink;
      this.faceBookLink = data.faceBookLink;
      this.faceBookLinkStatus = data.faceBookLinkStatus;
      this.twitterIconLink = data.twitterIconLink;
      this.twitterLink = data.twitterLink;
      this.twitterLinkStatus = data.twitterLinkStatus;
      this.instagramIconLink = data.instagramIconLink;
      this.instagramLink = data.instagramLink;
      this.instagramLinkStatus = data.instagramLinkStatus;
      this.webSiteIconLink = data.webSiteIconLink;
      this.webSiteLink = data.webSiteLink;
      this.webSiteLinkStatus = data.webSiteLinkStatus;
      this.brandDescriptionCurrent = data.brandDescriptionCurrent;
      this.brandDescriptionAr = data.brandDescriptionAr;
      this.brandDescriptionEn = data.brandDescriptionEn;
      this.brandDescriptionUnd = data.brandDescriptionUnd;
      this.brandImagePath = data.brandImagePath;
      this.brandNotes = data.brandNotes;
      this.brandArchiveStatus = data.brandArchiveStatus;
      this.activityTypeToken = data.activityTypeToken;
      this.dailyCode = data.dailyCode || "";
      this.fullCode = data.fullCode || "";
      this.activityTypeData.fillData(data.activityTypeData);
    }
  }

  async getAllBrands(language, userAuthorizeToken, pagination, filterData) {
    return await axios.get(
      `/api/Brands/GetAllBrands?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&activityTypeToken=${filterData.activityTypeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getReportBrands(language, userAuthorizeToken, pagination, filterData) {
    filterData.dateTimeFrom = filterData.dateFrom + " " + filterData.timeFrom;
    filterData.dateTimeTo = filterData.dateTo + " " + filterData.timeTo;
    try {
      let response = await axios.get(
        `/api/Brands/GetReportBrands?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&startActivationDateTime=${filterData.startActivationDateTime}&cardCreationPlanToken=${filterData.cardCreationPlanToken}&establishmentToken=${filterData.establishmentToken}&creationDateTime=${filterData.creationDateTime}&lastUpdateDateTime=${filterData.lastUpdateDateTime}&createdByUserToken=${filterData.createdByUserToken}&lastUpdatedByUserToken=${filterData.lastUpdatedByUserToken}&dateTimeFrom=${filterData.dateTimeFrom}&dateTimeTo=${filterData.dateTimeTo}&sendTo=${filterData.sendTo}&textSearch=${filterData.textSearch}`
      );
      if (response.data.status == STATUS.SUCCESS) {
        viewFileFromServer(response.data.reportPath);
      }
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
  async getReportBrandsSales(
    language,
    userAuthorizeToken,
    filterData = new BrandsFilter()
  ) {
    filterData.dateTimeFrom = filterData.dateFrom + " " + filterData.timeFrom;
    filterData.dateTimeTo = filterData.dateTo + " " + filterData.timeTo;
    try {
      let response = await axios.get(
        `/api/QrCodeStatistics/GetReportQrCodeStatistics?language=${language}&userAuthorizeToken=${userAuthorizeToken}&sendTo=${filterData.sendTo}&userSellerDateReportViewStatus=${filterData.userSellerDateReportViewStatus}&userClientDateReportViewStatus=${filterData.userClientDateReportViewStatus}&qrSessionDateReportViewStatus=${filterData.qrSessionDateReportViewStatus}&filterStatus=true&activityTypeToken=${filterData.activityTypeToken}&brandToken=${filterData.brandToken}&userSellerToken=${filterData.userSellerToken}&dateTimeFrom=${filterData.dateTimeFrom}&dateTimeTo=${filterData.dateTimeTo}`
      );
      if (response.data.status == STATUS.SUCCESS) {
        viewFileFromServer(response.data.reportPath);
      }
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getBrandDialog(
    language,
    userAuthorizeToken,
    filterData = new BrandsFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/Brands/GetBrandDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&activityTypeToken=${filterData.activityTypeToken}&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : brandImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: brandImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: brandImg,
      });
    }
    return options;
  }

  async getBrandDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/Brands/GetBrandDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.brandToken}`
    );
  }

  async addOrUpdateBrand(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("brandToken", this.brandToken);
    formData.append("activityTypeToken", this.activityTypeToken);
    formData.append("fullCode", this.fullCode);
    formData.append("brandNameEn", this.brandNameEn);
    formData.append("brandNameAr", this.brandNameAr);

    formData.append("brandDiscountPercentage", this.brandDiscountPercentage);
    formData.append("mapLink", this.mapLink);
    formData.append("mapLinkStatus", this.mapLinkStatus);
    formData.append("faceBookLink", this.faceBookLink);
    formData.append("faceBookLinkStatus", this.faceBookLinkStatus);
    formData.append("twitterLink", this.twitterLink);
    formData.append("twitterLinkStatus", this.twitterLinkStatus);
    formData.append("instagramLink", this.instagramLink);
    formData.append("instagramLinkStatus", this.instagramLinkStatus);
    formData.append("webSiteLink", this.webSiteLink);
    formData.append("webSiteLinkStatus", this.webSiteLinkStatus);
    formData.append("brandDescriptionAr", this.brandDescriptionAr);
    formData.append("brandDescriptionEn", this.brandDescriptionEn);
    formData.append("brandNotes", this.brandNotes);

    if (this.brandToken == "" || this.brandToken == undefined) {
      return await axios.post(`/api/Brands/AddBrand`, formData);
    } else {
      return await axios.post(`/api/Brands/UpdateBrand`, formData);
    }
  }

  async archiveBrand(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.brandToken,
      archiveStatus: true,
    };

    return await axios.post(`/api/Brands/ArchiveBrand`, data);
  }
}
