import axios from "axios";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import BaseEntityModel from "./../general/BaseEntityModel";
import ActivityTypesFilter from "./ActivityTypesFilter";
import i18n from "@/i18n";
import store from "@/store";
import activityTypesImg from "@/assets/images/activity-types.svg";

export default class ActivityType extends BaseEntityModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.activityTypeToken = "";
    this.dailyCode = "";
    this.fullCode = "";
    this.activityTypeNameCurrent = "";
    this.activityTypeNameEn = "";
    this.activityTypeNameAr = "";
    this.activityTypeDescriptionCurrent = "";
    this.activityTypeDescriptionAr = "";
    this.activityTypeDescriptionEn = "";
    this.activityTypeImagePath = "";
    this.activityTypeNotes = "";
    this.activityTypeArchiveStatus = "";
    this.mediaFile = "";
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.activityTypeToken = data.activityTypeToken;
      this.dailyCode = data.dailyCode || "";
      this.fullCode = data.fullCode || "";
      this.activityTypeNameCurrent = data.activityTypeNameCurrent;
      this.activityTypeNameEn = data.activityTypeNameEn;
      this.activityTypeNameAr = data.activityTypeNameAr;
      this.activityTypeDescriptionCurrent = data.activityTypeDescriptionCurrent;
      this.activityTypeDescriptionAr = data.activityTypeDescriptionAr;
      this.activityTypeDescriptionEn = data.activityTypeDescriptionEn;
      this.activityTypeImagePath = data.activityTypeImagePath;
      this.activityTypeNotes = data.activityTypeNotes;
      this.activityTypeArchiveStatus = data.activityTypeArchiveStatus;
    }
  }

  async getAllActivityTypes(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    return await axios.get(
      `/api/ActivityTypes/GetAllActivityTypes?language=${language}&userAuthorizeToken=${userAuthorizeToken}&timeZoneToken=&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&establishmentToken=${filterData.establishmentToken}&creationDateTime=${filterData.creationDateTime}&lastUpdateDateTime=${filterData.lastUpdateDateTime}&createdByactivityTypeToken=${filterData.createdByUserToken}&lastUpdatedByUserToken=${filterData.lastUpdatedByUserToken}&textSearch=${filterData.textSearch}`
    );
  }

  // async getDialogOfActivityTypes(language, userAuthorizeToken, filterData) {
  //   return await axios.get(
  //     `/api/ActivityTypes/GetActivityTypeDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
  //   );
  // }

  async getDialogOfActivityTypes(
    language,
    userAuthorizeToken,
    filterData = new ActivityTypesFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/ActivityTypes/GetActivityTypeDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : activityTypesImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: activityTypesImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: activityTypesImg,
      });
    }
    return options;
  }

  async getActivityTypeDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/ActivityTypes/GetActivityTypeDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.activityTypeToken}`
    );
  }

  async addOrUpdateActivityType(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("activityTypeToken", this.activityTypeToken);
    formData.append("fullCode", this.fullCode);
    formData.append("activityTypeNameAr", this.activityTypeNameAr);
    formData.append("activityTypeNameEn", this.activityTypeNameEn);
    formData.append(
      "activityTypeDescriptionAr",
      this.activityTypeDescriptionAr
    );
    formData.append(
      "activityTypeDescriptionEn",
      this.activityTypeDescriptionEn
    );

    formData.append("activityTypeNotes", this.activityTypeNotes);

    if (this.activityTypeToken == "" || this.activityTypeToken == undefined) {
      return await axios.post(`/api/ActivityTypes/AddActivityType`, formData);
    } else {
      return await axios.post(
        `/api/ActivityTypes/UpdateActivityType`,
        formData
      );
    }
  }

  async archiveActivityType(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.activityTypeToken,
      archiveStatus: true,
    };

    return await axios.post(`/api/ActivityTypes/ArchiveActivityType`, data);
  }
}
