export default class BrandsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentToken = "";
    this.creationDateTime = "";
    this.lastUpdateDateTime = "";
    this.createdByUserToken = "";
    this.lastUpdatedByUserToken = "";
    this.textSearch = "";
    this.activityTypeToken = "";
    this.dateTimeFrom = "";
    this.dateFrom = "";
    this.timeFrom = "";
    this.dateTimeTo = "";
    this.dateTo = "";
    this.timeTo = "";
    this.sendTo = "";
    //#region getReportBrandsSales
    this.userSellerDateReportViewStatus = false;
    this.userClientDateReportViewStatus = false;
    this.qrSessionDateReportViewStatus = false;
    this.brandToken = "";
    this.userSellerToken = "";
    //#endregion getReportBrandsSales
  }
  fillData(data) {
    this.establishmentToken = data.establishmentToken;
    this.creationDateTime = data.creationDateTime;
    this.lastUpdateDateTime = data.lastUpdateDateTime;
    this.createdByUserToken = data.createdByUserToken;
    this.lastUpdatedByUserToken = data.lastUpdatedByUserToken;
    this.textSearch = data.textSearch;
    this.activityTypeToken = data.activityTypeToken;
    this.dateTimeFrom = data.dateTimeFrom;
    this.dateFrom = data.dateFrom;
    this.timeFrom = data.timeFrom;
    this.dateTimeTo = data.dateTimeTo;
    this.dateTo = data.dateTo;
    this.timeTo = data.timeTo;
    this.sendTo = data.sendTo;
    this.userSellerDateReportViewStatus = data.userSellerDateReportViewStatus;
    this.userClientDateReportViewStatus = data.userClientDateReportViewStatus;
    this.qrSessionDateReportViewStatus = data.qrSessionDateReportViewStatus;
    this.brandToken = data.brandToken;
    this.userSellerToken = data.userSellerToken;
  }
}
